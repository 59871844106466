export const emptyAdress = {
  name: "",
  legalName: "",
  document: "",
  documentType: 0,
  country: {},
  city: "",
  district: "",
  street: "",
  number: "",
  complement: "",
  zipCode: "",
  isMaster: true,
  phoneNumber: "",
  email: "",
  stateRegistration: "",
  state: {
    name: "",
  },
};
